import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function GetQuotes(
  projectId,
  ordering = "",
  pagination = "",
  filtering = "",
  sellerId = null
) {
  const sellerFilter = sellerId ? ` seller_id: { _eq: "${sellerId}"},` : "";
  const response = await graphqlClient.query({
    query: gql`
    query getQuotes($projectId: uuid!) {
      quote(where: {
        project_id: {_eq: $projectId},
        ${sellerFilter}
        _or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}],
        ${filtering}
        }
        ${ordering} ${pagination}
        ) {
        id
        client {
          id
          email
          phone
          first_name
          last_name
        }
        user{
          first_name
          last_name
        }
        created_at
        status
        quote_status {
          id
          name
        }
        quote_details{
          property{
            unit_number
            property_type_id
          }
        }
      }
    }
    `,
    variables: {
      projectId,
    },
  });

  return response.data ? response.data.quote : [];
}

async function totalQuotesList(projectId, sellerId = null) {
  const sellerFilter = sellerId ? ` seller_id: { _eq: "${sellerId}" },` : "";
  const response = await graphqlClient.query({
    query: gql`
      query getQuotesTotal($projectId: uuid!) {
        quote_aggregate(
          where: {
            project_id: { _eq: $projectId },
            ${sellerFilter}
            _or: [{ deleted: { _is_null: true } }, { deleted: { _eq: false } }]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      projectId,
    },
  });
  return response.data ? response.data.quote_aggregate.aggregate.count : [];
}

const GetSummaryQuote = (id) =>
  graphqlClient.query({
    query: gql`
      query getProjectByID($id: uuid!) {
        quote(where: { id: { _eq: $id } }) {
          client {
            first_name
            last_name
          }
          quote_details {
            property {
              unit_number
              property_type_id
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
  });

const GetSingleQuote = (id) =>
  graphqlClient.query({
    query: gql`
      query getSingleQuote($id: uuid!) {
        quote_by_pk(id: $id) {
          id
          client {
            id
            email
            phone
            first_name
            last_name
            personal_document_url
            personal_document
            address
            person_reference
            pipedrive_deal_id
            pipedrive_person_id
          }
          user {
            id
            first_name
            last_name
            phone
            accounts {
              id
              email
            }
          }
          quote_details {
            property {
              id
              price
              rooms
              bathrooms
              total_area
              living_area
              balcony_area
              construction_area
              unit_number
              property_type_id
              property_type {
                id
                name
              }
              project_unit_type {
                id
                type
                img_url
                project_unit_type_images {
                  id
                  image_url
                }
              }
              _assigned_parking_spaces
              assigned_properties {
                property {
                  id
                  unit_number
                  property_type_id
                  property_status {
                    id
                    name
                  }
                }
              }
              property_images {
                id
                title
                img_url
                property_image_gallery
              }
              project {
                id
                name
                payment_days_reservation
              }
              status
              unit_number
              property_attribute_values {
                id
                property_attribute
                value
              }
            }
          }
          total
          status
          created_at
          expiration_date
          downpayment
          total_discounts
          quote_downpayments_aggregate {
            aggregate {
              count(columns: quote_id)
            }
          }
          reservation_amount
          currency {
            id
            name
            symbol
            code
          }
          project {
            id
            name
            payment_days_reservation
            allow_optioned
            property_developer {
              id
              name
            }
            city {
              country {
                name
                code
              }
            }
          }
          quote_downpayments(order_by: { due_date: asc }) {
            id
            due_date
            amount
            installment_type_id
          }
          prj_downpayment_rate
          prj_reservation
          prj_pcv_rate
          prj_max_payment_installments
          prj_downpayment_type_name
          prj_downpayment_type_id
          prj_pcv_days_to_pay
          payment_promise_amount
          reservation_amount
          total_to_finance
        }
      }
    `,
    variables: {
      id,
    },
  });

async function updateQuoteFields(quoteId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updQuote($fields: quote_set_input!) {
        update_quote_by_pk(pk_columns: {
          id: "${quoteId}"
        }, _set: $fields) {
          id
        }
      }
    `,
    variables: {
      fields,
    },
  });

  return response.data.update_property_by_pk;
}

async function updateQuotePrevious(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        update_quote(where: {
          quote_details:{
            property_id: {
              _eq: "${propertyId}"
            }
          },
          quote_status: {
            id: {
              _in:["OPEN", "OPTIONED"]
            }
          }
        }, _set: {
          status: "LOST"
        }) {
          affected_rows
        }
      }
    `,
  });

  return response.data.update_property_by_pk;
}

async function updateQuoteByProperty(propertyId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
    mutation updQuote($fields: quote_set_input!){
      update_quote(where: {quote_details: {property_id: {_eq:  "${propertyId}"}}}, _set: $fields) {
        affected_rows
      }
    }
    `,
    variables: {
      fields,
    },
  });
  return response.data.update_quote;
}

async function totalPropertyListAvailable(projectId, type = "APARTMENT") {
  const response = await graphqlClient.query({
    query: gql`
    {
      property_aggregate(where: {project_id: {_eq: "${projectId}"}, property_type: {id: {_eq: "${type}"}}, status: {_eq: AVAILABLE}, _or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}]}){
        aggregate{
          count
        }
      }
  }`,
  });
  return response.data.property_aggregate.aggregate.count;
}

async function listAvailable(
  projectId,
  type,
  ordering = "",
  pagination = "",
  filtering = ""
) {
  const boolPropType = {};
  boolPropType["id"] = type
    ? { _eq: type }
    : { _nin: ["PARKING", "WAREHOUSE"] };
  const response = await graphqlClient.query({
    query: gql`
    query listAvailable($boolPropType: property_type_bool_exp){
      property(where: {
          project_id: {_eq: "${projectId}"},
          property_type: $boolPropType,
          status: {_eq: AVAILABLE},
          _or:[
            {deleted: { _is_null: true }},
            {deleted: { _eq: false }}
          ],
          ${filtering}
        },
        ${ordering} ${pagination}) {
        id
        property_type {
          id
          name
        }
        status
        unit_number
        level
        rooms
        bathrooms
        price
        number
        unit_type
        unit_type_id
        balcony_area
        garden_area
        terrace_area
        project {
          id
          name
          country_currency {
            currency {
              id
              name
              code
              symbol
            }
          }
        }
        property_attribute_values {
          id
          value
          property_attribute
        }
        property_images {
          id
          img_url
          title
          type
          property_image_gallery
        }
        project_unit_type {
          id
          type
          img_url
          project_unit_type_images {
            id
            image_url
          }
        }
        property_status {
          id
          name
        }
        total_area
        assigned_properties(where: { _or:[
          {deleted: {_eq: false}},
          {deleted: {_is_null: true}}
        ]}) {
          property {
            id
            unit_number
            property_type_id
            price
          }
        }
        construction_area
        living_area
        balcony_area
      }
    }
    `,
    variables: {
      boolPropType
    }
  });

  return response.data.property;
}

async function getByStatus(projectId, propertyStatus) {
  const response = await graphqlClient.query({
    query: gql`
    {
      quote(where: {
        project_id: {
          _eq: "${projectId}"
        }
        _and:{
          status: {
            _eq: "${propertyStatus}"
          }
        }
      }) {
        id
            quote_details {
              property {
                project_unit_type {
                  type
                }
                id
                unit_number
                level
                living_area
                construction_area
                rooms
                bathrooms
                price
                updated_at
                property_type_id
              }
            }
            user {
              first_name
              last_name
            }
            client {
              first_name
              email
            }
            quote_status {
              id
              name
            }
      }
    }`,
  });

  return response.data.quote;
}

async function deleteById(quoteId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation d($quoteId: uuid!) {
        deleteQuote(quote: { id: $quoteId }) {
          id
          deleted
        }
      }
    `,
    variables: {
      quoteId,
    },
  });

  return response.data.deleteQuote || null;
}

async function GetQuotesOptionedCount() {
  const response = await graphqlClient.query({
    query: gql`
      {
        quote_aggregate(where: { status: { _eq: "OPTIONED" } }) {
          aggregate {
            count
          }
        }
      }
    `,
  });

  return response.data.quote_aggregate.aggregate.count;
}

async function optionQuote(quoteId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation optionQuote($quoteId: String!) {
        optionQuote(quoteId: $quoteId) {
          quote_id
        }
      }
    `,
    variables: {
      quoteId
    },
  });

  return response.data.optionQuote;
}

async function unOptionQuote(quoteId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation releaseQuote($quoteId: String!) {
        releaseQuote(quoteId: $quoteId) {
          quote_id
        }
      }
    `,
    variables: {
      quoteId
    },
  });

  return response.data.unOptionQuote;
}

export default {
  GetSummaryQuote,
  GetQuotes,
  GetSingleQuote,
  updateQuoteFields,
  updateQuotePrevious,
  updateQuoteByProperty,
  listAvailable,
  getByStatus,
  deleteById,
  GetQuotesOptionedCount,
  totalPropertyListAvailable,
  totalQuotesList,
  optionQuote,
  unOptionQuote
};