<template>
  <div v-if="this.quote">
    <iframe 
      :height="window.height" 
      :width="window.width"
      frameborder="0" 
      :src="quoteUrl">
    </iframe>
  </div>
</template>

<script>
import { quotesApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

const baseQuoterUrl = process.env.VUE_APP_RESERVATION_URL || 'https://reservation.uncierre.com/';

export default {
  async mounted() {
    try {
      this.window.width = "100%"; 
      this.window.height = window.innerHeight - 200; 

      this.quoteId = this.$route.params.id;
      this.quote = await quotesApi.GetSingleQuote(this.quoteId);
      this.quote = this.quote.data.quote_by_pk;
      this.jwt = localStorage.getItem("JWT");
    } catch (e) {
      sentryCaptureException(e, "quote");
    }
  },
  data() {
    return {
      quote: null,
      quoteId: null,
      jwt: null,
      window: {
        width: 200,
        height: 600
      }
    };
  },
  computed: {
    quoteUrl: function(){ return `${baseQuoterUrl}?quote=${this.quoteId}&tkn=${this.jwt}`}
  }
};
</script>
